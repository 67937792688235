<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="dangerFlagItems"
            itemText="codeName"
            itemValue="code"
            label="위험작업여부"
            name="dangerFlag"
            v-model="searchParam.dangerFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="작업현황 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="등록" v-if="editable" icon="add" @btnClicked="linkClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "work-location",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "공사현장",
            style: 'width:300px',
            align: "center",
            sortable: true,
          },
          {
            name: 'workName',
            field: 'workName',
            label: '작업명',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'workStartDt',
            field: 'workStartDt',
            label: '작업기간-시작일',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'workEndDt',
            field: 'workEndDt',
            label: '작업기간-종료일',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업 업체',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'workers',
            field: 'workers',
            label: '작업 인원',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'dangerFlag',
            field: 'dangerFlag',
            label: '위험작업여부',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: "useFlag",
            field: "useFlag",
            label: "사용여부",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        workDts: [],
        dangerFlag: null,
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      dangerFlagItems: [ // 위험작업여부
        { code: 'Y', codeName: '위험작업' },
        { code: 'N', codeName: '일반작업' },
      ],
      editable: true,
      listUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.work.loc.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "작업현황 상세"; // 작업현황 상세
      this.popupOptions.param = {
        sopWorkLocationId: row ? row.sopWorkLocationId : '',
        plantCd: row ? row.plantCd : this.searchParam.plantCd,
      };
      this.popupOptions.target = () => import(`${"./workLocationManageDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
